import React from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionPricing } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Button } from '../../../atoms'
import { ContentMd } from '../../commons'
import { PricingGrid } from './Pricing.styled'

const Pricing: React.FC<SectionPricing> = (props) => {
  const { id, sectionId, headline, plans } = props
  return (
    <Box as={'section'} id={sectionId || id} my={SECTION_MARGIN}>
      <Grid>
        {!!headline && (
          <Box mb={[8, 9, 10]}>
            <Row>
              <Col xs={12}>
                <Heading as={'h2'} type={'heading1'} textAlign={'center'}>
                  {headline}
                </Heading>
              </Col>
            </Row>
          </Box>
        )}
        {plans.length > 0 && (
          <Row center={'xs'}>
            <Col xs={12}>
              <PricingGrid plansLength={plans.length}>
                {plans.map((p) => (
                  <Flex
                    key={p.id}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    alignItems={'stretch'}
                    width={'100%'}
                    maxWidth={[380, 'unset', 'unset']}
                    mx={['auto', 'unset']}
                    height={'100%'}
                    p={[5, 5, 5, 6]}
                    borderRadius={'small'}
                    borderWidth={1}
                    borderStyle={'solid'}
                    borderColor={p.isHighlighted ? 'darkBlue' : 'dark'}
                    boxShadow={p.isHighlighted ? 'cardDarkBlue' : 'card'}
                  >
                    <Box
                      flex={'0 0 auto'}
                      pb={[4, 4, 5]}
                      borderWidth={1}
                      borderBottomStyle={'solid'}
                      borderColor={'grey10'}
                    >
                      <Heading as={'h3'} type={'heading2'}>
                        {p.name}
                      </Heading>
                      {!!p.price && (
                        <Box mt={[2, 2, 4]} minHeight={['unset', '50px']}>
                          <Heading as={'h4'} type={'heading5'} color={'darkBlue'} whiteSpace={'pre-line'}>
                            {p.price}
                          </Heading>
                        </Box>
                      )}
                    </Box>

                    {!!p.content && (
                      <Box flex={'1'}>
                        <ContentMd content={p.content} listItemColor={'darkBlue'} textType={'regular'} />
                      </Box>
                    )}

                    {p.button.length > 0 && (
                      <Box flex={'0 0 auto'} mt={[2, 2, 4]}>
                        {p.button.map((b) => (
                          <Button key={b.id} fluid {...b} />
                        ))}
                      </Box>
                    )}
                  </Flex>
                ))}
              </PricingGrid>
            </Col>
          </Row>
        )}
      </Grid>
    </Box>
  )
}

export default Pricing
