import React from 'react'
import styled from 'styled-components'
import { Box } from '../../../atoms'
import { mediaQueries } from '../../../theme'

export const PricingGrid: React.FC<{ plansLength: number }> = styled(Box)<{ plansLength: number }>`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(1, 1fr);

  ${mediaQueries.greaterThan('sm')`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${mediaQueries.greaterThan<{ plansLength: number }>('md')`
    grid-template-columns: ${({ plansLength }) => `repeat(${plansLength},1fr)`}
  `}
`
